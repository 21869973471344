<!-- eslint-disable max-len -->
<template>
  <svg viewBox="0 0 1147 1147" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1146.21 0H0V1146.21H1146.21V0Z" fill="#36599C" />
    <path
      d="M546.973 473.27C546.973 424.068 526.668 341.524 390.671 341.524H236.687L352.626 540.34H249.211V804.682H320.75V605.012H390.273L505.482 804.682H585.752L463.674 594.394C517.614 576.02 546.973 533.706 546.973 473.27ZM429.579 536.074L353.351 406.201H390.671C465.378 406.201 475.454 440.098 475.454 473.27C475.454 508.762 461.599 528.064 429.579 536.074Z"
      fill="white"
    />
    <path
      d="M739.924 804.481H898.834V541.286H774.685V605.844H834.276V739.924H739.924C698.785 739.924 665.435 706.574 665.435 665.435V481.695C665.435 440.556 698.785 407.206 739.924 407.206H898.834V342.649H739.924C663.131 342.649 600.878 404.902 600.878 481.695V665.435C600.878 742.228 663.131 804.481 739.924 804.481Z"
      fill="white"
    />
  </svg>
</template>
